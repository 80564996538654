
import { associates, companies } from "@/api";
import Logo from "@/components/common/Logo.vue";
import { Actions } from "@/store/models";
import Vue from "vue";
export default Vue.extend({
  components: { Logo },
  name: "Login",
  data() {
    return {
      image: require("/src/assets/Signup-new.jpg"),
      username: "",
    };
  },
  computed: {},
  methods: {
    login() {
      this.$router.push("/");
    },
    async signup() {
      if (!this.username) {
        return;
      }
      try {
        await companies.nlc.signupUser(this.username);
        this.$router.push({
          path: "/verifyemail",
          query: { email: this.username },
        });
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
  },
});
